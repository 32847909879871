.app * {
  box-sizing: border-box;
}

.app-body {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  margin: 0 auto;
  padding: 20px 48px 0;
  max-width: 800px;
}

.app-header {
  font-size: 24px;
  font-family: 'Bitter', serif;
  padding: 20px;
  display: flex; 
  align-items: center;
  justify-content: center;
  background-color: rgb(137, 170, 167)
}

.btn {
  outline: none; 
  padding: 8px 12px;
  font-family: inherit;
}

.btn--right {
  margin-left: auto;
}

.reference-image {
  position: relative;
  display: inline-block;
}

.reference-image__actions {
  display: flex;
  margin-bottom: 8px;
}

.reference-image__image {
  max-width: 100%;
}

.reference-image__image--bw {
  filter: grayscale(1);
}

.target-marker {
  display: none;
  position: absolute;
  width: 20px; 
  height: 20px;
  border-radius: 10px;
  border: 2px solid rgba(255, 255, 255, 0.75);
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
  pointer-events: none;
}

.target-marker--visible {
  display: block;
}

.target-marker__color {
  position: relative;
  width: 16px;
  height: 16px;
  border-radius: 8px;
}

.answer-area {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.value-selector {
  width: 100%;
}

.value-slider {
  position: relative;
}

.value-slider__range-slider {
  -webkit-appearance: none;
  margin: 0;
  background: linear-gradient(to right, white 0 12px, black calc(100% - 12px) 100%); 
  border: 1px solid grey;
  border-radius: 12px;
  height: 20px;
  width: 100%;

  &:not(:disabled) {
    cursor: pointer;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 20px; 
    height: 20px;
    border-radius: 10px;
    border: 2px solid white;
    box-shadow: 0px 0px 1px 2px rgba(0, 0, 0, 0.05);
  }

  &:focus {
    outline: none;
  }
}

.value-slider__range-slider--no-selection {
  &::-webkit-slider-thumb {
    visibility: hidden;
  }
}

.value-slider__correct-value {
  position: absolute;
  top: 0px;
  width: 20px; 
  height: 20px;
  border-radius: 10px;
  border: 2px solid lightseagreen;
  box-shadow: 0px 0px 1px 2px rgba(0, 0, 0, 0.05);
}

.value-slider__no-selection-message {
  font-style: italic;
}

.value-slider__swatches {
  display: flex;
  width: 100%;
  padding: 12px;
}

.value-slider__swatch-wrapper {
  flex: 1;
}

.value-slider__swatch {
  height: 24px;
  border: 1px solid grey; 
}

.value-slider__swatches--results {
  .value-slider__current-guess-swatch {
    border-right: none;  
  }
  
  .value-slider__answer-swatch {
    border-left: none;  
  }
}